<template>
  <div id="FeeStatementDataTable">
    <div class="elevation-1">
      <div class="v-table__overflow">
        <table class="v-datatable v-table theme--light">
          <thead>
            <tr>
              <th
                role="columnheader"
                scope="col"
                width="10"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                SL.
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Installment: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
                >
                Installment
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Number of Candidate(s): Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                No. of Candidate(s)
              </th>
              <!-- <th
                role="columnheader"
                scope="col"
                aria-label="Reference Id: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
                >
                Reference Id
              </th> -->             
              <!-- <th
                role="columnheader"
                scope="col"
                aria-label="Payment Status: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
                >
                Payment Status
              </th> -->
              <th
                role="columnheader"
                scope="col"
                aria-label="Total: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                Total Amount
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Action: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="getFeePaymentSlip.hasOwnProperty('all_std_fee')">
              <tr
                v-for="(item, index) in getFeePaymentSlip.all_std_fee" :key="index">         
                <td>{{ index + 1 }}</td>
                <td class="text-xs-left" style="white-space:nowrap">Installment - <span v-if="item.no_of_installment != null">{{ item.no_of_installment }}</span><span v-else-if="getFeePaymentSlip.all_std_fee.length > 2">{{ (Number(getFeePaymentSlip.all_std_fee[index-1].no_of_installment) + 1) }}</span><span v-else>{{ index + 1 }}</span></td>
                <td class="text-xs-left">{{ item.total_student }}</td>
                <!-- <td class="text-xs-left">{{ item.payment_reference_id }}</td> -->
                <!-- <td class="text-xs-left">
                  <span v-if="item.pms_payment_confirm == 0"> <v-chip class="ma-2" color="red" text-color="white">Unpaid</v-chip></span>
                  <span v-else> <v-chip class="ma-2" color="primary" text-color="white">Paid</v-chip></span>
                </td> -->
                <td class="text-xs-left">{{ item.fee }}</td>
                <td class="text-xs-left">                    
                  <v-btn small v @click="showPaymentSlip(item.payment_reference_id)" color="primary" dark>Fee Details</v-btn>
                  <!-- <v-btn small v-if="item.payment_reference_id != null">Download Fee Summary </v-btn> -->
                </td>
              </tr>
            </slot>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pre>{{getFeePaymentSlip}}</pre> -->
  </div>
</template>
<script>
import commonFun from "@/mixin/college_user_common_function.vue";
export default {
  mixins: [commonFun],
  props: ["getFeePaymentSlip"],
  data() {
    return {};
  },
  computed: {

  },
  methods:{
    showPaymentSlip(referenceId){
      this.$router.push(`/cu/ff/details_payment_slip/${referenceId}`);
    }
  }
};
</script>
<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 0px;
}
</style>
