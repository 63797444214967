<template>
  <div id="FeePaymentSlip">
    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-card-text class="title">Fee Summary</v-card-text>
          </v-card>
          <v-spacer />
        </v-layout>
      </v-card>
      <FeePaymentSlip :getFeePaymentSlip="getFeePaymentSlip" />
    </v-container>
  </div>
</template>
<script>
import FeePaymentSlip from "@/components/collegeUserPanel/formFillUp/feePaymentSlip";
import commonFun from "@/mixin/college_user_common_function.vue";

export default {
  mixins: [commonFun],
  components: {
    FeePaymentSlip
  },
  computed: {
    getFeePaymentSlip() {
      return this.$store.getters.getFeePaymentSlip;
    }
  },
  created() {
    this.getPaymentSlip();
  },
  methods: {
    downloadPdf() {
      let current_privilege = JSON.parse(
        localStorage.getItem("current-privilege")
      );
      this.$store.dispatch("clgUserFeePaymentSlipPdf", {
        params: {
          current_privilege: current_privilege
        },
        responseType: "blob"
      });
    },
    getPaymentSlip(){
        let current_privilege = JSON.parse(
          localStorage.getItem("current-privilege")
        );
        this.$store.dispatch("fetchPaymentSlip", {
          params: {
            current_privilege: current_privilege
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.logout(this.college_user_login_form_url);
          }
        });
    }
  }
};
</script>
